import React from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Dropdown, DropdownItem, DropdownMenu, Input, Spinner } from 'reactstrap';
import { debounce } from 'throttle-debounce';
import { SearchItem, type SearchResult } from './SearchItem';

interface Props {
  baseUrl: string;
  link: string;
}

export const Search: React.FC<Props> = ({ baseUrl, link }) => {
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [results, setResults] = React.useState<SearchResult[] | null>(null);
  const [dropdownOpen, setDropdownOpen] = React.useState(true);
  const containerRef = useDetectClickOutside({ onTriggered: () => setDropdownOpen(false) });

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const logJSONData = React.useCallback(
    async (value: string) => {
      const response = await fetch(link + '?query=' + value);
      const jsonData = await response.json();
      setResults(jsonData);
    },
    [link, setResults],
  );

  const debounceFunc = React.useCallback(
    debounce(300, value => {
      setLoading(true);

      logJSONData(value)
        .then(() => {
          setLoading(false);
        })
        .catch(console.error);
    }),
    [debounce],
  );

  React.useEffect(() => {
    if (value === '') {
      return;
    }
    debounceFunc(value);
  }, [value]);

  return (
    <div className="search-results-container" ref={containerRef}>
      <div className="search-input-container">
        <Input
          name="query"
          type="text"
          placeholder="Vyhledat…"
          aria-label="Search"
          onKeyUp={e => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              setDropdownOpen(false);
              const form: HTMLFormElement | null =
                document.querySelector('form')?.closest('*') ?? null;
              form?.submit();
            }
          }}
          onChange={e => {
            setValue(e.target.value);
          }}
          onFocus={() => {
            if ((results?.length ?? 0) > 0) {
              setDropdownOpen(true);
            }
          }}
          value={value}
        />

        {loading && <Spinner color="dark" size="sm" type="grow" className="search-spinner" />}
      </div>

      {results !== null && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownMenu className="w-100">
            {results.slice(0, 50).map(item => (
              <DropdownItem key={item.id}>
                <SearchItem baseUrl={baseUrl} result={item} />
              </DropdownItem>
            ))}
            {results.length === 0 && !loading && (
              <DropdownItem disabled>Nic jsme nenašli</DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  );
};
