import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import type { ParcelShop } from './d';
import { PplParcelShop } from './PplParcelShop';

interface Props {
  country: string;
}

export const PplParcelShopPicker: React.FC<Props> = ({ country }) => {
  const [parcelShop, setParcelShop] = React.useState<ParcelShop | null>(null);
  const [modal, setModal] = React.useState(false);
  const [elementIframe, setElementIframe] = React.useState<HTMLIFrameElement | null>(null);
  const [elementContainer, setElementContainer] = React.useState<HTMLDivElement | null>(null);
  const refInitialized = React.useRef(false);

  const handleContainer = React.useCallback((node: HTMLDivElement) => {
    setElementContainer(node);
  }, []);
  const handleIframe = React.useCallback((node: HTMLIFrameElement) => {
    setElementIframe(node);
  }, []);

  const toggle = () => setModal(!modal);

  React.useEffect(() => {
    if (elementContainer === null) {
      return;
    }

    let inputShippingContext: HTMLInputElement | null =
      elementContainer.closest('form')?.querySelector('input[name="shippingContext"]') ?? null;
    if (inputShippingContext === null) {
      return;
    }

    inputShippingContext.value = JSON.stringify({ pplParcelShop: parcelShop });
  }, [parcelShop]);

  React.useEffect(() => {
    if (elementIframe === null) {
      return;
    }

    if (refInitialized.current) {
      return;
    }

    refInitialized.current = true;

    // @ts-ignore
    const handler: EventListener = (event: Event & { detail: ParcelShop }) => {
      setParcelShop(event.detail);
      setModal(false);
    };

    elementIframe.onload = () => {
      elementIframe.contentWindow?.document.addEventListener('ppl-parcelshop-map', handler);
    };

    return () => {
      elementIframe.contentWindow?.document.removeEventListener('ppl-parcelshop-map', handler);
    };
  }, [elementIframe]);

  return (
    <div ref={handleContainer}>
      {parcelShop === null ? null : <PplParcelShop pplParcelShop={parcelShop} />}

      <Button color="danger" onClick={toggle} size="sm">
        Vybrat výdejní místo
      </Button>

      <Modal
        isOpen={modal}
        toggle={toggle}
        fullscreen={true}
        onClosed={() => {
          refInitialized.current = false;
        }}
      >
        <ModalHeader toggle={toggle}>Zvolte výdejní místo</ModalHeader>
        <ModalBody>
          <iframe
            ref={handleIframe}
            src={`/eshop/kosik/ppl?country=${country}`}
            className="w-100 h-100"
          />
        </ModalBody>
      </Modal>
    </div>
  );
};
