import React from 'react';
import type { ParcelShop } from './d';

interface Props {
  pplParcelShop: ParcelShop;
}
export const PplParcelShop: React.FC<Props> = ({ pplParcelShop }) => {
  return (
    <>
      {`${pplParcelShop.name} 
      (${pplParcelShop.street}, ${pplParcelShop.zipCode} ${pplParcelShop.city}, ${pplParcelShop.country})
      `}
    </>
  );
};
