import netteForms from 'nette-forms';
import 'bootstrap/js/dist/dropdown.js';
import '../styles/index.scss';
import { renderComponents } from './renderComponents';

if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
}

(window as any).Nette = netteForms;

netteForms.initOnLoad();

renderComponents(document);
