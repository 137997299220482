import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

interface Image {
  src: string;
  width: number;
  height: number;
}

interface Props {
  alt: string;
  imgLarge: Image;
  imgSmall: Image;
}

export const Photo: React.FC<Props> = ({ alt, imgLarge, imgSmall }) => {
  const [modal, setModal] = React.useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <Modal fullscreen isOpen={modal} toggle={toggle} fade={false}>
        <ModalHeader toggle={toggle}>{alt}</ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center align-items-center h-100">
            <img
              className="mw-100 mh-100"
              src={imgLarge.src}
              height={imgLarge.height}
              width={imgLarge.width}
              alt={alt}
            />
          </div>
        </ModalBody>
      </Modal>

      <img
        src={imgSmall.src}
        alt={alt}
        onClick={toggle}
        role="button"
        height={imgSmall.height}
        width={imgSmall.width}
      />
    </>
  );
};
