import React from 'react';

export interface SearchResult {
  id: number;
  formattedPrice: string;
  name: string;
  image: string;
  link: string;
}

interface Props {
  baseUrl: string;
  result: SearchResult;
}

export const SearchItem: React.FC<Props> = ({ baseUrl, result }) => {
  return (
    <a className="search-item" href={result.link} title={result.name}>
      <div className="search-image">
        <img src={`${baseUrl}/${result.image}`} alt={result.name} />
      </div>
      <div className="search-item-content">
        <span className="search-item-name">{result.name}</span>
        <span
          className="search-item-price"
          dangerouslySetInnerHTML={{ __html: result.formattedPrice }}
        ></span>
      </div>
    </a>
  );
};
