import React from 'react';
import { createRoot } from 'react-dom/client';
import { Photo } from './Components/Photo/Photo';
import { PplParcelShopPicker } from './Components/PplParcelShopPicker/PplParcelShopPicker';
import { Search } from './Components/Search/Search';

export const dataReactComponentAttributeName = 'data-react-component';

const components = {
  Photo,
  PplParcelShopPicker,
  Search,
};

export const renderComponents = (rootNode: Document) => {
  const nodeList = rootNode.querySelectorAll('[data-react-component]');
  if (nodeList.length === 0) {
    return;
  }

  Array.prototype.forEach.call(nodeList, (node: Element) => {
    const componentName = node.getAttribute(
      dataReactComponentAttributeName,
    ) as keyof typeof components;

    const root = createRoot(node);

    if (
      componentName === null ||
      componentName === undefined ||
      !Object.prototype.hasOwnProperty.call(components, componentName)
    ) {
      root.render(
        <div className="alert alert-danger">{`Component "${componentName ?? ''}" not found`}</div>,
      );

      return;
    }

    const propsAttribute = node.getAttribute('data-react-props');
    const props =
      propsAttribute === null || propsAttribute === undefined
        ? null
        : (JSON.parse(propsAttribute) as object);

    if (props !== null && typeof props !== 'object') {
      root.render(<div className="alert alert-danger">Provided props are not object</div>);

      return;
    }

    const Component = components[componentName] as React.FC;

    root.render(<Component {...props} />);
  });
};
